<template>
    <div>
        <CCard>
            <CCardHeader>
                <h3 class="mb-0">List Permohonan</h3>
            </CCardHeader>
            <CCardBody>
                 <div class="row">
          <div class="col-md-12">
                        <div class="row row-condensed">
                            <div class="as-col col-md">
                                <div class="form-group">
                                    <b for="">Tgl Dari</b>
                                    <vuejs-datepicker input-class="form-control no-readonly" v-model="start" format="dd MMM yyyy"></vuejs-datepicker>
                                </div>
                            </div>
                            <div class="as-col col-md">
                                <div class="form-group">
                                    <b for="">Tgl Sampai</b> 
                                    <vuejs-datepicker input-class="form-control no-readonly" v-model="end" format="dd MMM yyyy"></vuejs-datepicker>
                                </div>
                            </div>
                            <div class="as-col col-md-3">
                                <div class="form-group">
                                    <b for="">Perusahaan</b>
                                    <v-select :options="list_perusahaan" label="p_nama" v-model="perusahaan_selected" :value="$store.myValue"></v-select>
                                </div>
                            </div>
                            <div class="as-col col-md-3">
                                <div class="form-group">
                                    <b for="">Tipe Permohonan</b>
                                    <v-select :options="filter_tipe" :reduce="label => label.value" label="label" v-model="is_pemasukan" :value="$store.myValue"></v-select>
                                </div>
                            </div>
                            <div class="as-col col-md-2">
                                <div class="form-group">
                                    <b for="">Status</b>
                                    <v-select :options="filter_status" :reduce="label => label.value" label="label" v-model="status" :value="$store.myValue"></v-select>
                                </div>
                            </div>
                            <div class="as-col col-md">
                                <div class="form-group">
                                    <b for="">Search</b>
                                    <input type="text" class="form-control" v-model="search_permohonan">
                                </div>
                            </div>
                        </div>
                    </div>
          <div class="col-md-3">
            <div style="margin-top: 15px; margin-bottom: 15px">
              <div class="btn btn-sm btn-primary" v-on:click="filterPermohonan">
                <i class="fa fa-filter"></i> Filter
              </div>
              <div class="pull-right pr-1">
                <div class="btn btn-sm btn-success" v-on:click="getExcel">
                  <i class="fa fa-download"></i> Excel
                </div>
                <div
                  class="btn btn-sm btn-danger"
                  v-on:click="modalFilterPDF = true"
                >
                  <i class="fa fa-download"></i> PDF
                </div>
              </div>
            </div>
          </div>
        </div>
                <table class="table table-hover table-responsive table-bordered table-sm no-footer w-100">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th class="align-top" rowspan="2">No</th>
                            <th>Kode Permohonan</th>
                            <th>NIB Perusahaan</th>
                            <th>Nama Perusahaan</th>
                            <th>Kategori Permohonan</th>
                            <th>Nomor Surat</th>
                            <th>Tanggal Pengajuan</th>
              <th>Estimasi Waktu</th>
                            <th>Status</th>
                            <th class="align-top" rowspan="2">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template>
                            <tr v-if="loadingTable">
                                <td colspan="11"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                            </tr>
                            <tr v-for="(value_list_permohonan, key_list_permohonan) in list_permohonan" :key="key_list_permohonan" v-else>
                                <td>
                                    <template>
                                        <div v-if="key_list_permohonan+1 == 10">
                                            {{curent_page}}0
                                        </div>
                                        <div v-else>
                                            <template>
                                                <span v-if="curent_page-1 != 0">
                                                    {{curent_page-1}}{{key_list_permohonan+1}}
                                                </span>
                                                <span v-else>
                                                    {{key_list_permohonan+1}}
                                                </span>
                                            </template>
                                        </div>
                                    </template>
                                </td>
                                <td>{{value_list_permohonan.kode}}</td>
                                <td>
                                    <template>
                                        <div v-if="value_list_permohonan.tipe_pemohon_id != 2">{{value_list_permohonan.nib}}</div>
                                        <div v-else>Data Tidak Tersedia</div>
                                    </template>
                                </td>
                                <td>{{value_list_permohonan.perusahaan}}</td>
                                <td>{{value_list_permohonan.m_nama}}</td>
                                <td>{{value_list_permohonan.pr_nomor_surat}}</td>
                                <td>
                                    {{value_list_permohonan.pr_tgl_pengajuan}}
                                </td>
                <td>
                  <template v-if="value_list_permohonan.estimasi_hari">
                    {{ value_list_permohonan.estimasi_hari }} jam, atau <br />
                    {{ value_list_permohonan.due_date }}
                    {{ value_list_permohonan.jam_selesai }}
                  </template>
                </td>
                <td>
                                    <template>
                                        <CBadge :color="getBadge(value_list_permohonan.status_permohonan)" v-if="value_list_permohonan.status_permohonan == 'Pengajuan Permohonan'">
                                            <div>
                                                Draft
                                            </div>
                                        </CBadge>
                                        <CBadge :color="getBadge(value_list_permohonan.status_permohonan)" v-else>
                                            <div>
                                                {{value_list_permohonan.status_permohonan}}
                                            </div>
                                        </CBadge>
                                    </template>
                                </td>
                                <td>
                                    
                                    <template>
                                        <div v-if="value_list_permohonan.is_pemasukan">
                                            <router-link :to="{path: '/permohonan/detail-permohonan-pemasukan/' + value_list_permohonan.pr_kategori.replaceAll(' ','-') + '/' + value_list_permohonan.id}" class="btn btn-block btn-info btn-sm"><i class="fa fa-eye"></i> Detail</router-link>
                                        </div>
                                        <div v-else>
                                            <router-link :to="{path: '/permohonan/detail-permohonan-pengeluaran/' + value_list_permohonan.pr_kategori.replaceAll(' ','-') + '/' + value_list_permohonan.id}" class="btn btn-block btn-info btn-sm"><i class="fa fa-eye"></i> Detail</router-link>
                                        </div>
                                    </template>
                                       
                                    
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <nav aria-label="pagination">
                    <ul class="pagination justify-content-start">
                        <!---->
                        <!-- {{ page.active ? 'active' : '' }} -->
            <li
              v-for="(page, key_page) in paging"
              :key="key_page"
              :class="[
                'page-item',
                { active: page.active, disabled: !page.url },
              ]"
            >
              <a
                href="#"
                @click.prevent="page.url && toPage(page.url)"
                class="page-link"
              >
                <span v-html="page.label"></span>
              </a>
                        </li>
                    </ul>
                </nav>
        <!-- form Filter PDF -->
        <template>
          <div>
            <CModal
              title="Filter data"
              color="btn-primary"
              :show.sync="modalFilterPDF"
              size="md"
            >
              <CRow>
                <CCol md="6">
                  <div class="form-group">
                    <b for="">Tgl AWal</b>
                    <vuejs-datepicker
                      input-class="form-control no-readonly"
                      v-model="start_pdf"
                      format="dd MMM yyyy"
                    ></vuejs-datepicker>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <b for="">Tgl Akhir</b>
                    <vuejs-datepicker
                      input-class="form-control no-readonly"
                      v-model="end_pdf"
                      :disabled-dates="disabledDates"
                      format="dd MMM yyyy"
                    ></vuejs-datepicker>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <b for="">Perusahaan</b>
                    <v-select
                      :options="list_perusahaan"
                      label="p_nama"
                      v-model="perusahaan_selected_pdf"
                      :value="$store.myValue"
                    ></v-select>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <b for="">Tipe Permohonan</b>
                    <v-select
                      :options="filter_tipe"
                      :reduce="(label) => label.value"
                      label="label"
                      v-model="is_pemasukan_pdf"
                      :value="$store.myValue"
                    ></v-select>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <b for="">Status</b>
                    <v-select
                      :options="filter_status"
                      :reduce="(label) => label.value"
                      label="label"
                      v-model="status_pdf"
                      :value="$store.myValue"
                    ></v-select>
                  </div>
                </CCol>
              </CRow>
              <template #footer>
                <CButton @click="modalFilterPDF = false" color="secondary"
                  >Tutup</CButton
                >
                <CButton
                  v-on:click="getPDFLink()"
                  color="primary"
                  :disabled="isDownloading"
                  >{{ buttonLabel }}</CButton
                >
              </template>
            </CModal>
          </div>
        </template>

        <!-- form Filter PDF selesai -->
            </CCardBody>
        </CCard>
    </div>
</template>
<script>
    const null_array = [];
    export default {
        name: "ListPermohonan",
        components: {
            vuejsDatepicker
        },
        data() {
            return {
                // Search Column
                kode: '',
                nib: '',
                perusahaan: '',
                pemohon: '',
                pr_kategori: '',
                pr_kategori_module: this.$route.params.pr_kategori,
                m_nama: this.$route.query.m_nama,
                tahun_filter: this.$route.query.tahun,
                nib_filter: this.$route.query.nib,
                negara_pengirim_id: this.$route.query.negara_pengirim_id,
                status_filter: this.$route.query.status,
                pr_nomor_surat: '',
                pr_tanggal: '',
                status_permohonan_id: this.$route.query.status_permohonan_id,
                // Lain - Lain
                list_permohonan: null_array,
                paging: null_array,
                curent_page: '',
                loadingTable: false,
                session_data: JSON.parse(this.session),
                is_pemasukan: "",
                start: "",
                end: "",
                status: "",
                excel_url:"",
                list_perusahaan:"",
                perusahaan_selected:"",
                search_permohonan: "",
                filter_status: [
                    {
                        value: "baru",
                        label: "Baru",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                    {
                        value: "proses",
                        label: "Proses",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                ],
                filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Tanaman",
                    },
                    {
                        value: "0",
                        label: "Pengeluaran Benih Tanaman",
                    },
      ],

      // for filter pdf
      buttonLabel: "Export PDF",
      isDownloading: false,
      modalFilterPDF: false,
      start_pdf: "",
      end_pdf: "",
      status_pdf: "",
      perusahaan_pdf_selected: "",
      is_pemasukan_pdf: "",
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        from: new Date(8640000000000000),
        customPredictor: (date) => {
          if (!this.start_pdf) {
            return false;
          }
          const start = new Date(this.start_pdf);
          const end = new Date(this.start_pdf);
          end.setDate(start.getDate() + 31);

          return date < start || date > end;
        },
      },
            };
        },
        methods: {
            getExcel(){
                var win = window.open(this.excel_url, '_blank');
                if (win) {
                    //Browser has allowed it to be opened
                    win.focus();
                } else {
                    //Browser has blocked it
                    alert('Please allow popups for this website');
                }
            },
            filterPermohonan(){
                this.loadingTable = true;
                let string_search = '';
                // if (this.m_nama && this.status_permohonan_id) {
                    string_search = 
                    'm_nama:'+this.m_nama +'|'+
                    'status_id:'+this.status_permohonan_id +'|'+
                    'nib_filter:'+this.nib_filter +'|'+
                    'negara_pengirim_id:'+this.negara_pengirim_id +'|'+
                    'status_filter:'+this.status_filter;
                // }
                // alert(string_search);
                // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
                axios
                    .get(this.apiLink + "api/permohonan", {
                        params: {
                            is_pemasukan: this.is_pemasukan,
                            start: this.start,
                            end: this.end,
                            status: this.status,
                            // roles_id: this.session_data.roles_id,
                            search: string_search,
                            nib: this.perusahaan_selected?.nib,
                            pr_kategori: this.pr_kategori_module,
                            search_permohonan: this.search_permohonan,
                            tahun: this.tahun_filter
                        },
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_permohonan = response.data;
                        var res_permohonan_data = res_permohonan.data[0];
                        // console.log(res_permohonan_data);
                        if (res_permohonan.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_permohonan.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.list_permohonan = res_permohonan_data.data;
                            this.paging = res_permohonan_data.links;
                            this.curent_page = res_permohonan_data.current_page;
                            this.excel_url = res_permohonan.data[1];
                        }
                    });
            },
            toPage(url) {
                this.loadingTable = true;
                // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
                axios
                    .get(url, {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_permohonan = response.data;
                        var res_permohonan_data = res_permohonan.data[0];
                        // console.log(res_permohonan_data);
                        if (res_permohonan.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_permohonan.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.list_permohonan = res_permohonan_data.data;
                            this.paging = res_permohonan_data.links;
                            this.curent_page = res_permohonan_data.current_page;
                        }
                    });
            },
            getBadge(status) {
                switch (status) {
                    case "draft":
                        return "bg-dark";
                    case "Menunggu Perbaikan":
                        return "warning";
                    case "menunggu perbaikan":
                        return "warning";
                    case "Dibatalkan":
                        return "danger";
                    case "Selesai":
                        return "success";
                    case "ajukan":
                        return "info";
                    case "Evaluasi Dokumen Teknis":
                        return "primary";
                    default:
                        return "info";
                }
            },
    getPDFLink() {
      if (this.start_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      } else if (this.end_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      }
      this.buttonLabel = "Downloading data...";
      this.isDownloading = true;
      let fileName = "";
      const isPemasukan = parseInt(this.is_pemasukan_pdf, 10); 

      if (isPemasukan === 1) {
        fileName =
          "Export Permohonan Ijin Pemasukan Benih " +
          this.pr_kategori_module +
          ".pdf";
      } else if (isPemasukan === 0) {
        fileName =
          "Export Permohonan Ijin Pengeluaran Benih " +
          this.pr_kategori_module +
          ".pdf";
      } else {
        fileName = "Export Permohonan Ijin " + this.pr_kategori_module + ".pdf";
      }

      axios
        .get(this.apiLink + "api/permohonan/pdf-permohonan", {
          params: {
            is_pemasukan: this.is_pemasukan_pdf,
            start: this.start_pdf,
            end: this.end_pdf,
            status: this.status_pdf,
            nib: this.perusahaan_selected_pdf?.nib,
            pr_kategori: this.pr_kategori_module,
            tahun: this.tahun_filter,
          },
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          if (response.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Data tidak ditemukan",
            });
          } else {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
          }
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        })
        .catch((error) => {
          console.error("Error during PDF download", error);
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        });
    },
  },
        mounted(){
            $('.no-readonly').prop('readonly', false);
        },
        created() {
            let string_search = '';
            // if (this.m_nama && this.status_permohonan_id) {
                string_search = 
                'm_nama:'+this.m_nama +'|'+
                'status_id:'+this.status_permohonan_id +'|'+
                'nib_filter:'+this.nib_filter +'|'+
                'negara_pengirim_id:'+this.negara_pengirim_id +'|'+
                'status_filter:'+this.status_filter;
            // }
            // console.log(this.access_token);
            this.loadingTable = true;
            axios.get(this.apiLink + "api/permohonan", {
                params:{
                    is_pemasukan: this.is_pemasukan,
                    start: this.start,
                    end: this.end,
                    status: this.status,
                    // roles_id: this.session_data.roles_id,
                    search: string_search,
                    pr_kategori: this.pr_kategori_module,
                    tahun: this.tahun_filter
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_permohonan = response.data;
                var res_permohonan_data = res_permohonan.data[0];
                // console.log(res_permohonan.data[1]);
                if (res_permohonan.meta.code != "200") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res_permohonan.data.message,
                    });
                } else {
                    this.loadingTable = false;
                    this.list_permohonan = res_permohonan_data.data;
                    this.paging = res_permohonan_data.links;
                    this.curent_page = res_permohonan_data.current_page;
                    this.excel_url = res_permohonan.data[1];
                    
                }
            });
            axios.get(this.apiLink + "api/master/perusahaan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_perusahaan = response.data;
                var res_perusahaan_data = res_perusahaan.data;
                if (res_perusahaan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_perusahaan.data.message
                    });
                }
                else{
                    this.list_perusahaan = res_perusahaan_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
        },
	  watch: {
    start_pdf(val) {
      if (val) {
        const start = new Date(val);
        const end = new Date(start);
        end.setDate(start.getDate() + 31);

        this.disabledDates = {
          ranges: [
            {
              from: new Date(-8640000000000000),
              to: new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate()
              ),
            },
            {
              from: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
              to: new Date(8640000000000000),
            },
          ],
        };
      }
    },
  },

    };
</script>